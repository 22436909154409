import styled, { css } from 'styled-components';
import { DEVICES, pickThemeFontStyles } from '@/theme';
import { LayoutFooter } from '@/ui';

const baseColumnStyles = css`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

export const StyledFooter = styled(LayoutFooter)`
  &.ant-layout-footer {
    ${pickThemeFontStyles('14', '22', 'NORMAL')};
    color: ${({ theme }) => theme.token?.colorTextSecondary};
    background: ${({ theme }) => theme.token?.colorBgSecondary};
    padding-inline: 2rem;
    max-width: 27rem;
  }
`;

export const StyledContainer = styled.div`
  margin: 0 auto 1.5rem;
`;

export const StyledMainBlock = styled.div`
  display: grid;
  gap: 1rem;
`;

export const StyledBottomBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-block-start: 1.5rem;
`;

export const StyledContactsBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .anticon {
    font-size: 1.5rem;
  }
`;

export const StyledPhoneLink = styled.a`
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledText = styled.p`
  margin: 0;
`;

export const StyledImageHolder = styled.div`
  font-size: 2.5rem;
  position: relative;
  margin-block-end: 1rem;
  display: flex;
  justify-content: center;
  width: 100%;

  svg {
    max-width: 18rem;
    max-height: 6rem;
  }

  img {
    object-fit: cover;
    max-width: 18rem;
  }

  @media screen and ${DEVICES.TABLET} {
    display: block;
  }
`;

export const StyledColumnsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const StyledSubColumn = styled.ul`
  gap: 0.5rem;
  ${baseColumnStyles}
`;

export const StyledColumn = styled.ul`
  ${baseColumnStyles}
  flex-grow: 1;
`;

export const StyledTitle = styled.h3`
  ${pickThemeFontStyles('14', '24', 'BOLD')};
  margin-bottom: 0.5rem;
`;

export const StyledLinks = styled.a`
  color: inherit;
  text-decoration: underline;
  ${pickThemeFontStyles('14', '24')};

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledContacts = styled.div`
  display: flex;
  gap: 0.5rem;
`;
