import { useTranslation } from 'react-i18next';
import { UpdateCategoryFieldNames, MAX_KEYWORDS_LENGTH } from '../helpers';
import { useLengthValidationRule } from '@/hooks';

export const useUpdateCategoryFormRules = () => {
  const { t } = useTranslation(['validations']);
  const titleLengthRule = useLengthValidationRule({ t, max: 250 });
  const descriptionLengthRule = useLengthValidationRule({ t, max: 2000 });

  return {
    [UpdateCategoryFieldNames.IMAGE]: [],
    [UpdateCategoryFieldNames.THUMBNAIL]: [],
    [UpdateCategoryFieldNames.ADVANTAGES]: [],
    [UpdateCategoryFieldNames.ICON]: [],
    [UpdateCategoryFieldNames.VIDEO]: [],
    [UpdateCategoryFieldNames.DESCRIPTION]: [descriptionLengthRule],
    [UpdateCategoryFieldNames.SEO_BLOCK]: [],
    [UpdateCategoryFieldNames.TITLE]: [titleLengthRule],
    [UpdateCategoryFieldNames.SEO_TITLE]: [titleLengthRule],
    [UpdateCategoryFieldNames.SEO_DESCRIPTION]: [descriptionLengthRule],
    [UpdateCategoryFieldNames.SEO_KEYWORDS]: [
      {
        type: 'array' as const,
        validator(_: unknown, value: string[] = []) {
          if (value?.join(',')?.length > MAX_KEYWORDS_LENGTH) {
            return Promise.reject(
              new Error(t('validation_message_max_length', { ns: 'validation', max: MAX_KEYWORDS_LENGTH })),
            );
          }

          return Promise.resolve();
        },
      },
    ],
  };
};
