import { selectChannel } from '@/modules/Channels';
import { useAppDispatch, useAppState } from '@/store';
import { useEffect, useMemo } from 'react';
import { selectCategories, selectCategoriesIsLoading, getCategoriesTree } from '../feature';

export const useGetCategories = () => {
  const dispatch = useAppDispatch();
  const channel = useAppState(selectChannel);
  const categories = useAppState(selectCategories);
  const isLoading = useAppState(selectCategoriesIsLoading);

  useEffect(() => {
    if (!channel || !!categories || isLoading) return;
    dispatch(
      getCategoriesTree({
        channelId: channel.id,
      }),
    );
  }, [categories, channel, dispatch, isLoading]);

  return useMemo(() => ({ categories, isLoading }), [categories, isLoading]);
};
