// Globals
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

// Store
import { useAppDispatch, useAppState } from '@/store';
import { createMetatagsTemplate, deleteMetatagsTemplate, getMetatags, updateMetatagsTemplate } from '../../feature';

// Models
import { IMetaTags } from '@/models/metatags.model';

// Modules
import { selectChannel } from '@/modules/Channels';
import { Header } from '@/modules/Layout';

// Components
import { Modal, ModalSize } from '@/components';
import { PlusIcon } from '@/icons';
import { Button, Table } from '@/ui';
import { MetatagsForm } from '../../components';
import { StyledButtons, StyledContainer, StyledMessage, StyledPagination } from './MetatagsContainerStyles';
import { TrashIcon } from '@/icons';

// Helpers
import { CreateMetatagDTO, GetMetatagsDTO, UpdateMetatagDTO } from '../../dtos';
import {
  metatagsColumns,
  ModalViews,
  TCreateMetatagsTemplateFormValues,
  TUpdateMetatagsTemplateFormValues,
} from '../../helpers';
import { useGetMetatags } from '../../hooks';

const MetatagsContainer = () => {
  const { t } = useTranslation();
  const channel = useAppState(selectChannel);

  const { isLoading, metatags, pagination } = useGetMetatags({
    channelId: channel?.id,
  });
  const dispatch = useAppDispatch();

  const [selectedMetatag, setSelectedMetatag] = useState<IMetaTags | null>(null);
  const [modalView, setModalView] = useState<ModalViews | null>(null);

  const handleCloseModal = () => {
    setSelectedMetatag(null);
    setModalView(null);
  };

  const handleConfirmDelete = useCallback(async () => {
    if (!selectedMetatag) return;
    await dispatch(deleteMetatagsTemplate({ id: selectedMetatag.id })).unwrap();
    setSelectedMetatag(null);
    setModalView(null);
  }, [dispatch, selectedMetatag]);

  const handleDelete = (record: IMetaTags) => {
    setSelectedMetatag(record);
    setModalView(ModalViews.DELETE);
  };

  const handleSubmitEditMetatag = useCallback(
    async (values: TUpdateMetatagsTemplateFormValues) => {
      if (!selectedMetatag?.id || !channel?.id) return;
      const updateDto = new UpdateMetatagDTO({ ...values, id: selectedMetatag.id });
      try {
        await dispatch(updateMetatagsTemplate(updateDto)).unwrap();
        setSelectedMetatag(null);
        setModalView(null);
      } catch {}
    },
    [channel?.id, dispatch, selectedMetatag?.id],
  );

  const handleSubmitCreateMetatag = useCallback(
    async (values: TCreateMetatagsTemplateFormValues) => {
      setSelectedMetatag(null);
      if (!channel?.id) return;
      const createDto = new CreateMetatagDTO({ ...values, channelId: channel.id });
      const getDto = new GetMetatagsDTO({ channelId: channel.id });
      try {
        await dispatch(createMetatagsTemplate(createDto)).unwrap();
        await dispatch(getMetatags(getDto)).unwrap();
        setModalView(null);
      } catch {}
    },
    [channel?.id, dispatch],
  );

  const handleOpenEdit = (record: IMetaTags) => {
    setSelectedMetatag(record);
    setModalView(ModalViews.EDIT);
  };

  const handleOpenCreate = () => {
    setSelectedMetatag(null);
    setModalView(ModalViews.CREATE);
  };

  const handlePageChange = (page: number) => {
    const dto = new GetMetatagsDTO({ page, channelId: channel?.id ?? 0 });
    dispatch(getMetatags(dto));
  };

  const handleSearch = debounce(async (event: ChangeEvent<HTMLInputElement>) => {
    const dto = new GetMetatagsDTO({ page: '1', query: event.target.value, channelId: channel?.id ?? 0 });
    dispatch(getMetatags(dto));
  }, 500);

  const getModalContent = useCallback(
    (view: ModalViews) => {
      switch (view) {
        case ModalViews.EDIT:
          return (
            <MetatagsForm
              onSubmit={handleSubmitEditMetatag}
              isLoading={isLoading}
              initialValues={{
                description: selectedMetatag?.description,
                keywords: selectedMetatag?.keywords,
                title: selectedMetatag?.title,
                link: selectedMetatag?.link,
              }}
            />
          );
        case ModalViews.DELETE:
          return (
            <div>
              <StyledMessage>{t(`delete_description`)}</StyledMessage>
              <StyledButtons>
                <Button
                  type="primary"
                  danger
                  size="small"
                  onClick={handleConfirmDelete}
                  icon={<TrashIcon />}
                  loading={isLoading}
                >
                  {t('delete_metatag')}
                </Button>
              </StyledButtons>
            </div>
          );
        case ModalViews.CREATE:
          return <MetatagsForm isLoading={isLoading} onSubmit={handleSubmitCreateMetatag} siteURL={channel?.siteUrl} />;
        default:
          return null;
      }
    },
    [
      channel?.siteUrl,
      handleConfirmDelete,
      handleSubmitCreateMetatag,
      handleSubmitEditMetatag,
      isLoading,
      selectedMetatag?.description,
      selectedMetatag?.keywords,
      selectedMetatag?.link,
      selectedMetatag?.title,
      t,
    ],
  );

  const title = useMemo(() => {
    switch (modalView) {
      case ModalViews.EDIT:
        return t('title_edit_metatag', { name: selectedMetatag?.link ?? '' });
      case ModalViews.DELETE:
        return t('delete_metatag_confirm');
      case ModalViews.CREATE:
        return t('title_create_metatag');
      default:
        return null;
    }
  }, [modalView, selectedMetatag?.link, t]);

  return (
    <>
      <Header title={t('menu_metatags_templates')} suptitle={t('menu_seo')}>
        <Button icon={<PlusIcon />} type="primary" onClick={handleOpenCreate}>
          {t('btn_add_metatag')}
        </Button>
      </Header>
      <StyledContainer>
        <Table
          columns={metatagsColumns(t, handleSearch, handleDelete, handleOpenEdit)}
          rowKey="id"
          dataSource={metatags ?? []}
          loading={isLoading}
          pagination={false}
          size="small"
          virtual
          rowHoverable
        />
        <StyledPagination
          disabled={isLoading}
          total={pagination?.total ?? 0}
          current={pagination?.page ?? 1}
          pageSize={pagination?.pageSize ?? 10}
          onChange={handlePageChange}
          hideOnSinglePage
        />
      </StyledContainer>
      <Modal
        title={title}
        size={modalView === ModalViews.DELETE ? ModalSize.MEDIUM : ModalSize.HUGE}
        visible={!!modalView}
        onCancel={handleCloseModal}
        destroyOnClose
      >
        {!!modalView && getModalContent(modalView)}
      </Modal>
    </>
  );
};

export default MetatagsContainer;
