// Globals
import { Trans, useTranslation } from 'react-i18next';
import { useCallback } from 'react';

// Components
import { Modal, ModalSize } from '@/components';
import { UpdateCategoryForm } from '../../components';
import { StyledTitle } from './UpdateCategoryContainerStyles';

// Store
import { useAppDispatch, useAppState } from '@/store';
import { selectCategoriesIsLoading, getCategoriesTree, updateCategory } from '../../feature';

// Modules
import { selectChannel } from '@/modules/Channels';

// Models
import { ICategory } from '@/models/category.model';

// Helpers
import { TUpdateCategoryFormValues } from '../../helpers';
import { UpdateCategoryDTO } from '../../dtos';

type IUpdateCategoryContainerProps = {
  category: ICategory;
  setCategory: (category: ICategory | null) => void;
};

const UpdateCategoryContainer = ({ category, setCategory }: IUpdateCategoryContainerProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isLoading = useAppState(selectCategoriesIsLoading);
  const channel = useAppState(selectChannel);

  const handleUpdateCategory = useCallback(
    async (values: TUpdateCategoryFormValues) => {
      if (!category?.id || !channel?.id) return;
      const dto = new UpdateCategoryDTO({ ...values, id: category.id });
      try {
        await dispatch(updateCategory(dto)).unwrap();
        setCategory(null);
        await dispatch(
          getCategoriesTree({
            channelId: channel?.id,
          }),
        );
      } catch {}
    },
    [category.id, channel?.id, dispatch, setCategory],
  );

  const handleCloseDrawer = () => setCategory(null);

  return (
    <Modal
      visible={!!category}
      title={
        <StyledTitle>
          <Trans
            i18nKey="drawer_categories_title"
            values={{ name: category?.labels?.en_US, slug: category?.code }}
            components={{ b: <b />, span: <span /> }}
            t={t}
          />
        </StyledTitle>
      }
      size={ModalSize.HUGE}
      onCancel={handleCloseDrawer}
      destroyOnClose
    >
      <>{category && <UpdateCategoryForm category={category} onSubmit={handleUpdateCategory} loading={isLoading} />}</>
    </Modal>
  );
};

export default UpdateCategoryContainer;
