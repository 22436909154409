import { TreeDataNode } from '@/components';
import { PenIcon } from '@/icons';
import { ICategory } from '@/models/category.model';
import { Button } from '@/ui';
import { keyBy } from 'lodash';
import { StyledBlock, StyledImage, StyledImagePlaceholder } from '../components';

type MapCategoryToTree = ({
  categories,
  onClick,
  rootId,
}: {
  categories: ICategory[];
  onClick?: (category: ICategory) => void;
  rootId?: string;
}) => TreeDataNode;

export const mapCategoriesToTree: MapCategoryToTree = ({ categories, onClick, rootId }) =>
  categories?.map((category) => {
    const { labels, id, files } = category;
    const key = rootId ? `${rootId}_${id}` : id;

    const image = files?.find((file) => file.metaType === 'image')?.file?.url;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onClick?.({ ...category });
    };

    return {
      title: (
        <div>
          <span>{labels.en_US}</span>
          <StyledBlock>
            {image ? <StyledImage src={image} alt={labels.en_US} loading="lazy" /> : <StyledImagePlaceholder />}
            <Button type="primary" onClick={handleClick} icon={<PenIcon />} />
          </StyledBlock>
        </div>
      ),
      key,
      children: mapCategoriesToTree({
        categories: category?.children ?? [],
        onClick,
        rootId: key,
      }),
      isLeaf: !category?.children?.length,
      className: rootId ? 'leaf' : undefined,
    };
  });

export const getInitialFormValues = (category: ICategory) => {
  const { labels, advantages: categoryAdvantages, files: tempFiles, description, seo } = category;

  const advantages = Array.isArray(categoryAdvantages) ? categoryAdvantages : [];
  const files = keyBy(tempFiles, 'metaType');

  const image = files?.image?.file?.url;
  const video = files?.video?.file?.url;
  const icon = files?.icon?.file?.url;
  const thumbnail = files?.thumbnail?.file?.url;

  return {
    ...category,
    name: labels.en_US,
    seoKeywords: seo?.keywords,
    seoDescription: seo?.description,
    seoTitle: seo?.title,
    description: description?.en_US,
    advantages,
    image,
    video,
    icon,
    thumbnail,
  };
};
