import { showApiErrors } from '@/utils';
import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { getShippingPrices, updateShippingPrice, updateShippingPrices } from './actionCreators';
import { SLICE_NAME, ShippingPriceState, initialState } from './models';

export const shippingPricesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getShippingPrices.fulfilled, (state: ShippingPriceState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.shippingPrices = payload;
      })
      .addCase(updateShippingPrice.fulfilled, (state: ShippingPriceState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.shippingPrices = (state?.shippingPrices ?? []).map((shippingPrice) =>
          shippingPrice.id === payload.id ? payload : shippingPrice,
        );
      })
      .addCase(updateShippingPrices.fulfilled, (state: ShippingPriceState, action) => {
        const { payload } = action;
        state.isLoading = false;
        // Create a map of the updated shipping prices by ID for fast lookup
        const updatedPricesMap = new Map(payload.map((item) => [item.id, item]));

        const updatedShippingPrices = state.shippingPrices?.map(
          (shippingPrice) => updatedPricesMap.get(shippingPrice.id) ?? shippingPrice,
        );

        state.shippingPrices = updatedShippingPrices || null;
      })
      .addMatcher(
        isPending(getShippingPrices, updateShippingPrice, updateShippingPrices),
        (state: ShippingPriceState) => {
          state.isLoading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isRejected(getShippingPrices, updateShippingPrice, updateShippingPrices),
        (state: ShippingPriceState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;
          showApiErrors(error);
        },
      );
  },
});
