export enum CategoryEndpoints {
  ROOT = '/categories',
  TREE = '/categories/tree',
}

export const staticCategoriesEndpoints = {
  GET_CATEGORIES: CategoryEndpoints.ROOT,
  GET_CATEGORIES_TREE: CategoryEndpoints.TREE,
};

export const getCategoryEndpoints = ({ id }: { id: string }) => ({
  UPDATE_CATEGORY: `${CategoryEndpoints.ROOT}/${id}`,
});

export interface IFile {
  createdAt: string;
  hash: string;
  id: string;
  mimeType: string;
  name: string;
  type: string;
  updatedAt: string;
  url: string;
}

export interface ICategory {
  id: string;
  parent: string;
  updated: string;
  advantages?: string[];
  akeneoImagePath?: string;
  description?: Record<string, string>;
  children?: ICategory[];
  code: string;
  seo: {
    title?: string;
    description?: string;
    keywords?: string[];
  };
  labels: Record<string, string>;
  parentId: string;
  files: {
    metaType: string;
    file: IFile;
  }[];
}

// ============== DTO ==============

export interface IGetCategoriesDTO {
  channel: string;
}

export interface IAdvantage {
  title: string;
  description: string;
}

export interface IUpdateCategoryDTO {
  id: string;
  advantages?: IAdvantage[] | null;
  image?: Blob | string | null;
  icon?: Blob | string | null;
  video?: Blob | string | null;
  thumbnail?: Blob | string;
  seoBlocks?: string[] | null;
  description?: string | null;
  seo?: {
    title?: string | null;
    description?: string | null;
    keywords?: string[] | null;
  };
}

export interface IGetCategoriesTreeDTO {
  channelId: number;
}

// ============== Response ==============

export type IGetCategoriesResponse = ICategory;
