export enum AkeneoSynkEndpoints {
  ROOT = '/akeneo-sync',
}

export const staticAkeneoSyncEndpoints = {
  SYNC_PRODUCTS: `${AkeneoSynkEndpoints.ROOT}/products`,
  SYNC_PRODUCTS_MODELS: `${AkeneoSynkEndpoints.ROOT}/product-models`,
  SYNC_ENTITY: `${AkeneoSynkEndpoints.ROOT}/sync-entity`,
  GET_ENTITIES_INFO: `${AkeneoSynkEndpoints.ROOT}/entities-info`,
};

export const getSyncEndpoints = ({ type }: { type: string }) => ({
  SYNC_BY_ENTITY: `${AkeneoSynkEndpoints.ROOT}/${type}`,
});

export enum EntityTypes {
  MEASUREMENTS = 'measurements',
  ASSOCIATION_TYPES = 'association_types',
  CATEGORIES = 'categories',
  ATTRIBUTES = 'attributes',
  FAMILIES = 'families',
}

export type IEntityInfo = {
  id: string;
  entityType: EntityTypes;
  status: string;
  createdAt: string;
  updatedAt: string;
};

// ============== DTO ==============

export interface IBaseAkeneoSyncDTO {
  channelSlugs: string[];
  updatedFrom: string;
  updatedUntil: string;
}

export type IAkeneoSyncProductsDTO = IBaseAkeneoSyncDTO & {
  type: string;
};

export type IAkeneoSyncEntityDTO = {
  entityTypes: EntityTypes[];
};

// ============== Response ==============

export interface IBaseAkeneoSyncResponse {
  success: boolean;
}

export type IGetEntitiesInfoResponse = Record<
  EntityTypes,
  {
    id: string;
    entityType: EntityTypes;
    status: string;
    createdAt: string;
    updatedAt: string;
  }
>;
