// Globals
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Button, Input, TextEditor, Select } from '@/ui';
import { StyledForm, StyledFormItem, StyledFormListItem } from './UpdateCategoryFormStyles';
import { FormItem, FormList, FormListError } from '@/components';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

// Models
import { ICategory } from '@/models/category.model';

// Helpers
import {
  ALLOWED_ICON_TYPES,
  ALLOWED_IMAGE_TYPES,
  ALLOWED_VIDEO_TYPES,
  getInitialFormValues,
  TUpdateCategoryFormValues,
  UpdateCategoryFieldNames,
  RECOMMENDED_ICON_FILE_TYPES,
  RECOMMENDED_THUMB_FILE_TYPES,
} from '../../helpers';
import { useUpdateCategoryFormRules } from '../../hooks';

// Modules
import { ImageUpload, VideoUpload } from '@/modules/Settings';

type IUpdateCategoryFormProps = {
  onSubmit?: (values: TUpdateCategoryFormValues) => void;
  category: ICategory;
  loading?: boolean;
};

const UpdateCategoryForm = ({ category, onSubmit, loading }: IUpdateCategoryFormProps) => {
  const { t } = useTranslation();
  const rules = useUpdateCategoryFormRules();
  const initialValues = useMemo(() => getInitialFormValues(category), [category]);

  return (
    <StyledForm onFinish={onSubmit} initialValues={initialValues}>
      <div>
        <StyledFormItem
          label={t('label_category_image')}
          name={UpdateCategoryFieldNames.IMAGE}
          rules={rules[UpdateCategoryFieldNames.IMAGE]}
          tooltip={t('category_banner_image_help')}
        >
          <ImageUpload
            accept={ALLOWED_IMAGE_TYPES.join(',')}
            recommendedSize={{ width: 1920, height: 320 }}
            recommendedFormats={RECOMMENDED_THUMB_FILE_TYPES}
          />
        </StyledFormItem>
        <StyledFormItem
          label={t('label_category_thumbnail')}
          name={UpdateCategoryFieldNames.THUMBNAIL}
          rules={rules[UpdateCategoryFieldNames.THUMBNAIL]}
          tooltip={t('category_thumbnail_image_help')}
        >
          <ImageUpload
            accept={ALLOWED_IMAGE_TYPES.join(',')}
            recommendedSize={{ width: 304, height: 304 }}
            recommendedFormats={RECOMMENDED_THUMB_FILE_TYPES}
          />
        </StyledFormItem>
        <StyledFormItem
          label={t('label_category_icon')}
          tooltip={t('category_icon_image_help')}
          name={UpdateCategoryFieldNames.ICON}
        >
          <ImageUpload
            accept={ALLOWED_ICON_TYPES.join(',')}
            recommendedSize={{ width: 64, height: 64 }}
            recommendedFormats={RECOMMENDED_ICON_FILE_TYPES}
          />
        </StyledFormItem>
        <StyledFormItem
          label={t('label_category_video')}
          tooltip={t('category_video_image_help')}
          name={UpdateCategoryFieldNames.VIDEO}
        >
          <VideoUpload accept={ALLOWED_VIDEO_TYPES.join(',')} recommendedSize={{ width: 1920, height: 320 }} />
        </StyledFormItem>
        <StyledFormItem
          label={t('column_description')}
          name={UpdateCategoryFieldNames.DESCRIPTION}
          rules={rules[UpdateCategoryFieldNames.IMAGE]}
        >
          <TextEditor placeholder={t('description')} />
        </StyledFormItem>
        <FormItem
          name={UpdateCategoryFieldNames.SEO_TITLE}
          label={t('update_page_form_seo_title')}
          rules={rules[UpdateCategoryFieldNames.SEO_TITLE]}
        >
          <Input />
        </FormItem>
        <FormItem
          name={UpdateCategoryFieldNames.SEO_DESCRIPTION}
          label={t('update_page_form_seo_description')}
          rules={rules[UpdateCategoryFieldNames.SEO_DESCRIPTION]}
        >
          <Input />
        </FormItem>
        <StyledFormItem
          name={UpdateCategoryFieldNames.SEO_KEYWORDS}
          label={t('update_page_form_seo_keywords')}
          rules={rules[UpdateCategoryFieldNames.SEO_KEYWORDS]}
        >
          <Select dropdownStyle={{ display: 'none' }} mode="tags" suffixIcon={null} />
        </StyledFormItem>
        <FormList name={UpdateCategoryFieldNames.ADVANTAGES}>
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name, ...field }) => (
                <Fragment key={key}>
                  <StyledFormListItem required={false} label={t('label_advantage', { index: key + 1 })}>
                    <FormItem
                      {...field}
                      name={[name, UpdateCategoryFieldNames.TITLE]}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={rules[UpdateCategoryFieldNames.TITLE]}
                    >
                      <Input placeholder={t('label_advantage_title')} style={{ width: '60%' }} />
                    </FormItem>
                    <FormItem
                      {...field}
                      name={[name, UpdateCategoryFieldNames.DESCRIPTION]}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={rules[UpdateCategoryFieldNames.DESCRIPTION]}
                      label={t('label_advantage_description')}
                      noStyle
                    >
                      <TextEditor placeholder={t('label_advantage_description')} />
                    </FormItem>
                  </StyledFormListItem>
                  {fields.length > 1 ? (
                    <Button
                      style={{ width: '60%' }}
                      type="primary"
                      danger
                      size="small"
                      icon={<MinusCircleOutlined />}
                      onClick={() => remove(name)}
                    />
                  ) : null}
                </Fragment>
              ))}
              <FormItem>
                <Button
                  size="small"
                  onClick={() => add()}
                  style={{ width: '60%', marginTop: '0.5rem' }}
                  icon={<PlusOutlined />}
                >
                  {t('add_advantage')}
                </Button>
                <FormListError errors={errors} />
              </FormItem>
            </>
          )}
        </FormList>
        <FormList name={UpdateCategoryFieldNames.SEO_BLOCK}>
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name, ...field }) => (
                <Fragment key={key}>
                  <StyledFormListItem required={false} label={t('label_seo_block', { index: key + 1 })}>
                    <FormItem
                      {...field}
                      name={[name, UpdateCategoryFieldNames.TITLE]}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={rules[UpdateCategoryFieldNames.TITLE]}
                    >
                      <Input placeholder={t('label_title')} style={{ width: '60%' }} />
                    </FormItem>
                    <FormItem
                      {...field}
                      name={[name, UpdateCategoryFieldNames.DESCRIPTION]}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={rules[UpdateCategoryFieldNames.DESCRIPTION]}
                      label={t('label_description')}
                      noStyle
                    >
                      <TextEditor placeholder={t('label_description')} />
                    </FormItem>
                  </StyledFormListItem>
                  {fields.length > 1 ? (
                    <Button
                      style={{ width: '60%' }}
                      type="primary"
                      danger
                      size="small"
                      icon={<MinusCircleOutlined />}
                      onClick={() => remove(name)}
                    />
                  ) : null}
                </Fragment>
              ))}
              <FormItem>
                {fields.length < 3 && (
                  <Button
                    size="small"
                    onClick={() => add()}
                    style={{ width: '60%', marginTop: '0.5rem' }}
                    icon={<PlusOutlined />}
                  >
                    {t('add_seo_block')}
                  </Button>
                )}
                <FormListError errors={errors} />
              </FormItem>
            </>
          )}
        </FormList>
      </div>
      <Button type="primary" block size="large" htmlType="submit" loading={loading}>
        {t('btn_save')}
      </Button>
    </StyledForm>
  );
};

export default UpdateCategoryForm;
